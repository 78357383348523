import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, NumberInput } from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';
import { ASSET_TYPE, TEMPERATURE_TRAKING_STATE } from "./index"
export const LockEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect="show">
            <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
            <SelectInput
                allowEmpty
                emptyText='cancel'
                source="assetType"
                label="Asset Type"
                choices={ASSET_TYPE}
            />
            <TextInput label="Asset Identifier" source="assetIdentifier"  />
            <SelectInput
                source="temperatureTrackingState"
                label="Temperature Tracking State"
                choices={TEMPERATURE_TRAKING_STATE}
            />
            <TextInput
                source="temperatureTrackingMacAddress"
                label="Temperature Tracking Mac Address"
            />
            <NumberInput source="temperatureTarget" label="Temperature Target" />
            <NumberInput source="temperatureOffset" label="Temperature Offset" />
            <BaseEdit />
        </SimpleForm>
    </Edit>
);