import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, NumberInput, minValue, required } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';

import { ASSET_TYPE, TEMPERATURE_TRAKING_STATE } from "./index"
export const LockCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
            <SelectInput
                allowEmpty
                emptyText='cancel'
                source="assetType"
                label="Asset Type"
                choices={ASSET_TYPE}
            />
            <TextInput label="Asset Identifier" source="assetIdentifier"  />
            <SelectInput
                defaultValue={'disabled'}
                source="temperatureTrackingState"
                label="Temperature Tracking State"
                choices={TEMPERATURE_TRAKING_STATE}
            />
            <TextInput
                source="temperatureTrackingMacAddress"
                label="Temperature Tracking Mac Address"
            />
            <NumberInput source="temperatureTarget" label="Temperature Target" />
            <NumberInput source="temperatureOffset" label="Temperature Offset" />
            <NumberInput validate={[required('Il nome è obbligatorio'), minValue(1)]} defaultValue={1} source="version" label="Version" />
            <BaseCreate />
        </SimpleForm>
    </Create>
);