import React, { useState } from 'react';
import { SimpleShowLayout, Show, TextField, DateField, useDataProvider, useRefresh, showNotification } from 'react-admin';
import {  Typography, Box, CircularProgress, Button  } from '@material-ui/core';
import { getColorForStatus } from './utils'
import { Grid, Container } from '@material-ui/core';
import { DeliveryManMissionJobList } from '../missonJob/DeliveryManMissionJobList'
import { makeStyles } from '@material-ui/core/styles';
import { GET_OFF_RESOURCE_PLAIN } from '../../../../providers/nestjs_crud';
import { Permissions } from '../../../../permissions/permissions'; 
import { useDispatch } from 'react-redux';     

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1)
    }
}));

export const DeliveryManMissionShow = (props) => {
    const classes = useStyles()
    return (<>
        <Container maxWidth="xl" className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xl={12} lg={12} md={12} xs={12}>
                    <Show {...props}>
                        <SimpleShowLayout>
                            <Actions label="Stato" />
                            <TextField label='Luogo' source="place.name" />
                            <DateField showTime locales="it-IT" label="Data e ora prevista" source="estimatedStartDate" />
                            <DateField showTime locales="it-IT" label="Data e ora massima di esecuzione" source="maxExecutionDate" />
                            <TextField label="Commento" source="comment" />
                            <TextField label="Tipo di fallimento" source="failureType" />
                        </SimpleShowLayout>
                    </Show>
                </Grid>
            </Grid>
                <Grid container spacing={3}>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                        <DeliveryManMissionJobList missionId={props.id} />
                    </Grid>
                </Grid>
        </Container>   
    </>
        
)};

function Actions (props) {
    const [state, setState] = useState('INITIAL')
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    if(props.record && props.record.status) {
        const backgroundColor = getColorForStatus(props.record.status);
        if (state === 'FETCHING_DATA'){
            return ( 
            <Box textAlign='center'>
                <CircularProgress size={25} />
            </Box>  
            )
        }

        const {id, actions } = props.record 
        return (
            <Box display='flex' justifyContent='start' alignItems="flex-end">
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                    {props.label && <Typography  >
                        {props.label}   
                    </Typography>}
                    <Typography
                    component='span'
                        style={{ backgroundColor: backgroundColor[300], padding: '6px', borderRadius: '4px' }}>
                        {props.record.status}
                    </Typography>
                </Box>
                {props.record.actions && actions.map((action, index) => (
                    <Button 
                        size='medium' 
                        variant='contained'
                        style={{ marginLeft: '10px'}}
                        color='primary' 
                        key={index}
                        onClick={() => {
                            setState('FETCHING_DATA')
                            dataProvider(GET_OFF_RESOURCE_PLAIN, `${Permissions.deliveryManMission.feature}/${id}/do-action/${action.key}`)
                            .then((rawResponse) => {
                                const response = JSON.parse(rawResponse)
                                if(response.success) {
                                    dispatch(showNotification(`azione: ${action.label} completata`));
                                    refresh();
                                } else if(response.errors) {
                                    const message = response.errors.map(({constraints}) => {
                                        return Object.keys(constraints).map(key => constraints[key]).join(', ');
                                    }).join(', ');

                                    throw new Error(message)
                                } else {
                                    throw new Error('unknown error')
                                }
                            })
                            .catch(error => {
                                setState('INITIAL')
                                dispatch(showNotification(`Errore durante azione: ${action.label} ${error}`, 'error'));
                            })
                        }}
                        >
                        {action.label}
                    </Button>
                ))}
            </Box>
        );
    }
    return null;
}


