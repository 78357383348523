import React from 'react';
import { Create, SimpleForm, TextInput, FormDataConsumer, ReferenceInput, AutocompleteInput } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { required } from 'react-admin';
import { WarehouseLocationType, WarehouseLocationUsage, WarehouseLocationType_Warehouse, WarehouseLocationType_WarehouseLocation, WarehouseLocationUsageView, WarehouseLocationUsageStorage, WarehouseLocationUsageTransit, WarehouseLocationUsageInventory, WarehouseLocationUsageWaste, WarehouseLocationUsageClient, WarehouseLocationUsageSupplier } from './enum';
import { Permissions } from '../../../permissions/permissions';
import { useForm } from 'react-final-form';
import RichTextInput from 'ra-input-rich-text';

const Relation = () => {
    const form = useForm();

    if(!form.getFieldState('type') || (form.getFieldState('type') && !form.getFieldState('type').value)) {
		return null;
    }

    if(form.getFieldState('type').value === WarehouseLocationType_Warehouse) {
        return  (
            <ReferenceInput
                validate={[required('campo obbligatorio')]}
                filterToQuery={searchText => {
                    if(!searchText)
                        return null;

                    return { "name": searchText }
                }}
                label="Luogo"
                source="placeId"
                reference={`${Permissions.place.feature}`}
            >
                <AutocompleteInput
                    allowEmpty
                    shouldRenderSuggestions={val => val && val.length >= 2 }
                    optionText={(record)=> `${record ? record.id : ''} ${record ? record.name : ''}`}
                    optionValue="id"
                />
            </ReferenceInput>
        );
    }
    else if(form.getFieldState('type').value === WarehouseLocationType_WarehouseLocation) {
        return (
            <ReferenceInput
                validate={[required('campo obbligatorio')]}
                filterToQuery={searchText => {
                    if(!searchText)
                        return null;

                    return { "name": searchText }
                }}
                label="Parent Location"
                source="parentId"
                reference={`${Permissions.warehouseLocation.feature}`}
            >
                <AutocompleteInput
                    allowEmpty
                    shouldRenderSuggestions={val => val && val.length >= 2 }
                    optionText={(record)=> `${record ? record.id : ''} ${record ? record.name : ''}`}
                    optionValue="id"
                />
            </ReferenceInput>
        );
    }

    return null;
}
const Usage = () => {
    const form = useForm();

    if(!form.getFieldState('type') || (form.getFieldState('type') && !form.getFieldState('type').value)) {
		return null;
    }

    if(form.getFieldState('type').value === WarehouseLocationType_Warehouse) {
        return  (
            <AutocompleteInput label="Utilizzo" source="usage" choices={[WarehouseLocationUsageView].map(usage => {
                return {
                    id: usage,
                    name: usage
                }
            })}
            validate={[required('campo obbligatorio')]}
        />
        );
    }
    else if(form.getFieldState('type').value === WarehouseLocationType_WarehouseLocation) {
        return (
            <AutocompleteInput label="Utilizzo" source="usage" choices={WarehouseLocationUsage.map(usage => {
                return {
                    id: usage,
                    name: usage
                }
            })}
            validate={[required('campo obbligatorio')]}
        />
        );
    }

    return null;
}

export const WasteInventory = () => {
    const form = useForm();

    if(!form.getFieldState('usage') || (form.getFieldState('usage') && !form.getFieldState('usage').value)) {
		return null;
    }
    if(form.getFieldState('usage').value === WarehouseLocationUsageStorage || form.getFieldState('usage').value === WarehouseLocationUsageTransit) {
        return  (
            <>
            <ReferenceInput
            validate={[required('campo obbligatorio')]}
            filter={{ usage: WarehouseLocationUsageInventory }}
            filterToQuery={searchText => {
                if(!searchText)
                    return {};

                return {
                    name: searchText,
                    usage: WarehouseLocationUsageInventory
                }
            }}
            label="Inventory"
            source="inventoryId"
            reference={`${Permissions.warehouseLocation.feature}`}
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val && val.length >= 2 }
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
            validate={[required('campo obbligatorio')]}
            filter={{ usage: WarehouseLocationUsageWaste }}
            filterToQuery={searchText => {
                if(!searchText)
                    return {};

                return {
                    name: searchText,
                    usage: WarehouseLocationUsageWaste
                }
            }}
            label="Waste"
            source="wasteId"
            reference={`${Permissions.warehouseLocation.feature}`}
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val && val.length >= 2 }
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
        </>
        );
    }

    return null;
}

export function Owner() {
    const form = useForm();

    if(!form.getFieldState('usage') || (form.getFieldState('usage') && !form.getFieldState('usage').value)) {
		return null;
    }

    if([WarehouseLocationUsageSupplier, WarehouseLocationUsageStorage, WarehouseLocationUsageClient, WarehouseLocationUsageInventory, WarehouseLocationUsageTransit, WarehouseLocationUsageWaste].includes(form.getFieldState('usage').value)) {
        const type = {
            Supplier: 'SupplierUser',
            Client: 'B2BUser',
            Storage: 'OwnCompany',
            Transit: 'OwnCompany',
            Inventory: 'OwnCompany',
            Waste: 'B2BUser',
        }[form.getFieldState('usage').value];

        return  (
            <ReferenceInput
                filter={{ type }}
                filterToQuery={searchText => {
                    if(!searchText)
                        return {
                            type,
                        }

                    return {
                        label: searchText,
                        type,
                    }
                }}
                source="ownerId"
                reference={`${Permissions.mainUser.feature}`}
                >
                <AutocompleteInput
                    optionText={(record)=> `${record ? record.id : ''} ${record ? record.label : ''}`}
                    shouldRenderSuggestions={val => val && val.length >= 2 }
                    optionValue="id" />
            </ReferenceInput>
        );
    }

    return null;
}

export function  DDTEmail() {
    const form = useForm();

    if(!form.getFieldState('usage') || (form.getFieldState('usage') && !form.getFieldState('usage').value)) {
		return null;
    }

    if([WarehouseLocationUsageStorage, WarehouseLocationUsageClient, WarehouseLocationUsageWaste].includes(form.getFieldState('usage').value)) {
        return  (
            <TextInput label="ddtEmail" source="ddtEmail" />
        );
    }

    return null;
}

export const Locker = () => {
    const form = useForm();

    if(!form.getFieldState('usage') || (form.getFieldState('usage') && !form.getFieldState('usage').value)) {
		return null;
    }
    if(form.getFieldState('usage').value === WarehouseLocationUsageStorage) {
        return  (
            <ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return null;

                return { "name": searchText }
            }}
            label="Locker"
            source="lockerId"
            reference={`${Permissions.locker.feature}`}
        >
            <AutocompleteInput
                allowEmpty
                shouldRenderSuggestions={val => val && val.length >= 2 }
                optionText={(record)=> `${record ? record.id : ''} ${record ? record.name : ''}`}
                optionValue="id"
            />
        </ReferenceInput>
        );
    }

    return null;
}

export const WarehouseLocationCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Nome" source="name" validate={[required('campo obbligatorio')]} />
            <TextInput label="Nome corto" source="shortName" validate={[required('campo obbligatorio')]} />
            <AutocompleteInput label="Tipo" source="type" choices={WarehouseLocationType.map(type => {
                    return {
                        id: type,
                        name: type
                    }
                })}
                validate={[required('campo obbligatorio')]}
            />
            <FormDataConsumer>
                {formDataProps => (
                    <Relation {...formDataProps} />
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {formDataProps => (
                    <Usage {...formDataProps} />
                )}
            </FormDataConsumer>
            <RichTextInput
                label="Nota"
                source="note" />
            <FormDataConsumer>
                {formDataProps => (
                    <WasteInventory {...formDataProps} />
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {formDataProps => (
                    <Locker {...formDataProps} />
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {formDataProps => (
                    <Owner {...formDataProps} />
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {formDataProps => (
                    <DDTEmail {...formDataProps} />
                )}
            </FormDataConsumer>
            <ReferenceInput
                filterToQuery={searchText => {
                    if(!searchText)
                    return null;

                    return {
                        name: searchText,
                    }
                }}
                source="businessUnitId"
                reference={`${Permissions.businessUnit.feature}`}>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
            </ReferenceInput>
            <BaseCreate />
        </SimpleForm>
    </Create>
);

