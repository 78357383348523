import React from 'react';
import { Datagrid, List, TextField, Filter, TopToolbar, useGetOne, Loading } from 'react-admin';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import { BulkDeleteButton } from 'react-admin';

function CondtionFilters(props) {
    return (
        <Filter {...props}>
        </Filter>
    );
}

function ConditionActions(props) {
    let history = useHistory();

    return (
        <TopToolbar {...props}>
            <Button variant="contained" color="primary" startIcon={<AddIcon/>} onClick={() => {
                history.push(`/condition/create?parentId=${props.filterValues['promotionId||eq']}`);
            }}>CREATE</Button>
        </TopToolbar>
    )
}
function NonUndoableBulkActionButtons(props) {
    return (
        <React.Fragment>
        {props.buttons}
        <BulkDeleteButton {...props} undoable={false} />
        </React.Fragment>
    );
}

export function ConditionList(props) {
    const params = new URLSearchParams(props.location.search);
    const filter = params.get('filter');
    const { data, loading, error } = useGetOne(`promotion`, JSON.parse(filter)["promotionId||eq"]);

    if(loading || !data || error) {
        return (<Loading />);
    }

    return (
        <List
            {...props}
            title={`${data.name} > Condition`}
            actions={<ConditionActions />}
            filters={<CondtionFilters />}
            perPage={25}
            bulkActionButtons={<NonUndoableBulkActionButtons buttons={props.bulkActionButtons} />}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="factType"/>
                <TextField source="operator"/>
                <TextField source="value"/>
            </Datagrid>
        </List>
    )
}