import React, { useEffect, useState } from 'react';
import { Datagrid, List, TextField, TextInput, SelectInput, useUnselectAll, useDataProvider, usePermissions, useRefresh, NumberInput, NumberField  } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';
import { ASSET_TYPE, TEMPERATURE_TRAKING_STATE } from "./index"
import { Button } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, TextField as MUITextField, Typography, IconButton } from '@material-ui/core';
import { Permissions } from '../../permissions/permissions';
import { useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	closeButton: {
        position: 'absolute',
        right: theme.spacing(0),
        top: theme.spacing(0),
        padding: theme.spacing(1)
	},
}));
const LockFilter = (props) => (
    <Filter {...props}>
        <NumberInput label="ID" source="id||eq" alwaysOn/>
        <TextInput label="Nome" source="name" alwaysOn />
        <SelectInput
            label="Asset Type"
            source="assetType"
            choices={ASSET_TYPE}
            alwaysOn 
        />
        <SelectInput
            source="temperatureTrackingState"
            label="Temperature Tracking State"
            choices={TEMPERATURE_TRAKING_STATE}
            alwaysOn 
        />
        <NumberInput source="version" label="Version" />
    </Filter>
);

export const LockList = props => (
    <List {...props} actions={<ListActions />} filters={<LockFilter />} bulkActionButtons={<ActionButtons />}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <NumberField source="version" label="Version" />
            <TextField label="ThingName" source="thingName" />
            <TextField source="assetType" label="Asset Type" />
            <TextField source="temperatureTrackingState" label="Temperature Tracking State" />
        </Datagrid>
    </List>
);

export function ActionButtons (props) {
    const { permissions } = usePermissions();

    return (
        <>
            {permissions && permissions.includes(`${Permissions.lock.feature}-${Permissions.lock.actions.bulkUpdateVersion}`) && <CustomBulkAction {...props} />}
        </>
    );
}

const CustomBulkAction = ({ selectedIds }) => {
    const unselectAll = useUnselectAll();
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);
    const [version, setVersion] = useState(1);
    const [state, setState] = useState('INITIAL');
    const [lastMessage, setLastMessage] = useState('');

    useEffect(() => {
        if(state === 'START_UPDATE') {
            setState('UPDATING');
            dataProvider('POST_OFF_RESOURCE', `${Permissions.lock.feature}/bulk-update-version`, {
                data: {
                    ids: selectedIds,
                    version
                }
            })
            .then(data => {
                if(data.data.success === false) {
                    setLastMessage(data.data.message);
                    throw new Error();
                }
                unselectAll(Permissions.lock.feature);
                setOpen(false);
                refresh();
                setState('INITIAL');
                setLastMessage('');
            })
            .catch((e) => {
               setState('UPDATING_ERROR');
            });
        }
    }, [dataProvider, dispatch, refresh, selectedIds, state, unselectAll, version]);

    return (
        <>
            <Button size='small' variant='outlined' endIcon={<PublishIcon />} label="Update Version" onClick={() => {setOpen(true)}} color='primary'>
                UPDATE ({selectedIds.length})
            </Button>
            <Dialog
                open={open}
                onClose={() => {setOpen(false)}}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='lg'
                fullWidth={true}
            >
                <DialogTitle id="scroll-dialog-title">UPDATE {selectedIds.length} devices via OTA</DialogTitle>
                <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={() => {
                        setOpen(false);
                        unselectAll(Permissions.lock.feature);
                        setLastMessage('');
                    }}>
				<CloseIcon />
			</IconButton>
                <DialogContent   dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        {lastMessage && state === 'UPDATING_ERROR' && <Typography style={{color: 'red'}} variant='body1' gutterBottom>{lastMessage}</Typography>}
                        <MUITextField
                            label="Version"
                            type='number'
                            value={version}
                            onChange={(e) => {
                                setVersion(e.target.value)
                            }}>
                       </MUITextField>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={['START_UPDATE', 'UPDATING'].includes(state)} size='large' variant='contained' endIcon={<PublishIcon />} label="Update Version" onClick={() => {setState('START_UPDATE')}} color='primary'>
                        START OTA UPDATE
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};